import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HomePage from './pages/HomePage';
import ProgrammingPage from './pages/ProgrammingPage';
import MultipleChoicePage from './pages/MultipleChoicePage';
import UpgradePage from './pages/UpgradePage';
import AutoLogin from "./pages/AutoLogin";
import HelpPage from "./pages/HelpPage";
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import NavBar from './components/NavBar'; // 导入NavBar组件

import { lightTheme, darkTheme } from './theme';
const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Router>
        <NavBar isDarkMode={isDarkMode} toggleTheme={toggleTheme} /> {/* 传递isDarkMode和toggleTheme */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/multiple-choice" element={<MultipleChoicePage />} />
          <Route path="/programming" element={<ProgrammingPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />

        <Route path="/login" element={<AutoLogin />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;