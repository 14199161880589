import React from 'react';
import { Box, Chip, Typography, IconButton } from '@mui/material';
import SelectAllIcon from '@mui/icons-material/SelectAll'; // 引入全选图标

const TagFilter = ({ tags, selectedTags, setSelectedTags }) => {
  const handleTagClick = (tag, isCategory) => {
    if (isCategory) {
      const categoryTags = tags[tag];
      const allSelected = categoryTags.every((t) => selectedTags.includes(t));
      if (allSelected) {
        setSelectedTags(selectedTags.filter((t) => !categoryTags.includes(t)));
      } else {
        setSelectedTags([...new Set([...selectedTags, ...categoryTags])]);
      }
    } else {
      if (selectedTags.includes(tag)) {
        setSelectedTags(selectedTags.filter((t) => t !== tag));
      } else {
        setSelectedTags([...selectedTags, tag]);
      }
    }
  };

  const getColor = (category) => {
    switch (category) {
      case 'Python基础知识':
        return 'primary';
      case '二级公共基础':
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      {Object.keys(tags).map((category) => (
        <Box key={category} sx={{ mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <Typography variant="subtitle1" gutterBottom>
              {category}
            </Typography>
            <IconButton
              size="small"
              onClick={() => handleTagClick(category, true)}
              color={tags[category].every((tag) => selectedTags.includes(tag)) ? getColor(category) : 'default'}
              sx={{ ml: 1 }}
            >
              <SelectAllIcon />
            </IconButton>
          </Box>
          {tags[category].map((tag) => (
            <Chip
              key={tag}
              label={tag}
              onClick={() => handleTagClick(tag, false)}
              color={selectedTags.includes(tag) ? getColor(category) : 'default'}
              sx={{
                m: 0.5,
                minWidth: '120px', // 最小宽度以保证文字展示，但允许标签根据内容自适应增长
                justifyContent: 'center',
                height: '36px', // 稍微加大高度，增加可读性
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderRadius: '6px', // 使用更适中的圆角
                fontSize: '0.875rem', // 保持适中的字体大小
                padding: '4px 12px', // 稍微加大的内部填充，确保文字不会拥挤
              }}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default TagFilter;
