// src/components/ProgrammingPage/ConfirmDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ConfirmDialog = ({
  isOpen,
  onCancel,
  onConfirm,
}) => (
  <Dialog open={isOpen} onClose={onCancel}>
    <DialogTitle>确认替换代码</DialogTitle>
    <DialogContent>
      <DialogContentText>
        当前编辑器中已有代码，您确定要用答案代码替换现有内容吗？此操作不可撤销。
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="warning">
        取消
      </Button>
      <Button onClick={onConfirm} color="primary">
        确认
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
