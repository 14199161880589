import React, { useState, useEffect } from "react";
import { CircularProgress, Snackbar, Alert, Button } from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom"; // 用于获取 URL 参数和路由跳转

const AutoLogin = () => {
  const [isLoading, setIsLoading] = useState(false); // 加载状态
  const [showSnackbar, setShowSnackbar] = useState(false); // 控制提示显示
  const [snackbarMessage, setSnackbarMessage] = useState(""); // 提示信息
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 提示类型 ('success' or 'error')
  const [countdown, setCountdown] = useState(3); // 跳转倒计时
  const location = useLocation(); // 获取当前 URL
  const navigate = useNavigate(); // 跳转功能

  useEffect(() => {
    // 将 getUidFromUrl 移动到 useEffect 内部
    const getUidFromUrl = () => {
      const params = new URLSearchParams(location.search);
      return params.get("uid");
    };

    const uid = getUidFromUrl();

    if (uid) {
      setIsLoading(true); // 设置加载状态

      // 自动发送登录请求
      const loginWithUid = async () => {
        try {
          const response = await axios.post("/auth/login", { uid });
          if (response.status === 200 && response.data.token) {
            const { token } = response.data;

            // 将 JWT 存储在 localStorage 中
            localStorage.setItem("token", token);

            // 设置成功提示信息
            setSnackbarMessage(`登录成功！${countdown} 秒后跳转。`);
            setSnackbarSeverity("success");
            setShowSnackbar(true);

            // 开始倒计时
            const interval = setInterval(() => {
              setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                  clearInterval(interval);
                  navigate("/"); // 跳转到主页
                }
                return prevCountdown - 1;
              });
            }, 1000);
          } else {
            setSnackbarMessage("登录失败，请检查登录链接。");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
          }
        } catch (error) {
          setSnackbarMessage("登录失败，请重试。");
          setSnackbarSeverity("error");
          setShowSnackbar(true);
        } finally {
          setIsLoading(false); // 结束加载状态
        }
      };

      loginWithUid();
    } else {
      setSnackbarMessage("缺少登录参数。");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  }, [location, navigate, countdown]); // 移除多余的逗号

  return (
    <div>
      {isLoading && <CircularProgress />}

      {/* 提示信息通过 Snackbar 显示，并向下移动且放大 */}
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // 位置在顶部中间
        style={{ marginTop: "100px", maxWidth: "600px" }} // 向下移动且放大提示框
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity}
          style={{
            fontSize: "18px",
            padding: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }} // 放大字体，所有内容居中在一行
        >
          {`${snackbarMessage.replace("3", countdown)} `}
          {snackbarSeverity === "success" && (
            <>
              如果没有自动跳转，请
              <Button
                onClick={() => navigate("/")}
                color="inherit"
                style={{
                  padding: "0 8px",
                  fontSize: "18px",
                  minWidth: "unset",
                }} // 按钮紧凑设计，与文字合为一行
              >
                点击这里
              </Button>
              手动跳转。
            </>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AutoLogin;
