import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MultipleChoiceQuestion from "../components/MultipleChoiceQuestion";
import MultipleChoiceSwitcher from "../components/MultipleChoiceSwitcher";
// import questionsData from "../data/Choice.json"; // 已注释掉
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CryptoJS from "crypto-js"; // 引入加密库

const MultipleChoicePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // 控制提示的显示和隐藏，默认在移动端不显示提示
  const [showAlert, setShowAlert] = useState(!isMobile);
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false); // 用于控制购买提示框
  const isMember = isUserMember(); // 通过 JWT 检查用户是否是会员

  // 新增：初始化 questions 状态
  const [questions, setQuestions] = useState([]); // 用于存储解密后的问题数据

  // 用于跟踪当前用户的答案和答题状态
  const [userAnswers, setUserAnswers] = useState([]); // 初始化为空数组
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 毫秒 = 5 秒

      // 清理定时器
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  // 解析 JWT 并检查是否有效（会员状态）
  function isUserMember() {
    const token = localStorage.getItem("token"); // 从 localStorage 获取 JWT

    if (!token) {
      return false; // 没有 token 表示用户未登录或未认证
    }

    try {
      // 解码 JWT
      const payload = JSON.parse(atob(token.split(".")[1]));

      // 获取当前时间戳
      const currentTime = Math.floor(Date.now() / 1000);

      // 检查 token 是否过期 (exp 是 UNIX 时间戳)
      if (payload.exp && payload.exp > currentTime) {
        return true; // 如果 exp 大于当前时间，JWT 仍然有效
      } else {
        return false; // JWT 已过期
      }
    } catch (error) {
      console.error("Invalid JWT token:", error);
      return false;
    }
  }

  // 动态加载并解密加密的 JSON 数据
  useEffect(() => {
    const loadEncryptedData = async () => {
      try {
        const response = await fetch("/Choices_encrypted_full.json");
        const encryptedData = await response.text();

        // 使用 AES 解密
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        // 解析解密后的数据并设置问题
        const parsedQuestions = decryptedData.map((q, index) => ({
          id: index + 1, // 使用索引作为 id
          question: q.question,
          options: q.options,
          answer: parseInt(q.answer) - 1, // 转换为从 0 开始的索引
          explanation: q.explanation,
          tags: [q.tags],
        }));

        setQuestions(parsedQuestions); // 设置问题数据

        // 新增：根据加载的问题数量初始化 userAnswers
        setUserAnswers(Array(parsedQuestions.length).fill(null));
      } catch (error) {
        console.error("Error loading or decrypting data:", error);
      }
    };

    loadEncryptedData(); // 加载数据
  }, []);

  // 定义 filteredQuestions 变量（限制前 100 道题只对非会员有效）
  const filteredQuestions = questions.filter(
    (question) =>
      selectedTags.length === 0 ||
      selectedTags.some((tag) => question.tags.includes(tag))
  );

  // 使用 useCallback 缓存函数，避免每次渲染时重新创建
  const handleAnswer = useCallback(
    (index, selectedOption) => {
      const newAnswers = [...userAnswers];
      newAnswers[index] = selectedOption;
      setUserAnswers(newAnswers);
    },
    [userAnswers]
  );

  const handleNext = useCallback(() => {
    setCurrentQuestionIndex((prevIndex) => {
      if (!isMember && prevIndex >= 99) {
        setShowPurchaseDialog(true);
        return prevIndex;
      }
      return Math.min(prevIndex + 1, filteredQuestions.length - 1);
    });
  }, [filteredQuestions.length, isMember, setShowPurchaseDialog]);

  const handlePrevious = useCallback(() => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // 先关闭提示框
      setShowAlert(false);

      // 处理键盘事件
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key >= "1" && event.key <= "4") {
        if (!isMember && currentQuestionIndex >= 100) {
          setShowPurchaseDialog(true);
          return;
        }
        handleAnswer(currentQuestionIndex, parseInt(event.key) - 1);
      }
    };

    // 添加事件监听
    window.addEventListener("keydown", handleKeyDown);

    // 清理事件监听
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    currentQuestionIndex,
    handleAnswer,
    handleNext,
    handlePrevious,
    isMember,
    setShowPurchaseDialog,
  ]); // 正确设置依赖项

  const tags = {
    Python基础知识: [
      "Python - 基础",
      "Python - 控制",
      "Python - 函数",
      "Python - 组合",
      "Python - 文件",
      "Python - 生态",
    ],
    二级公共基础: [
      "数据结构与算法",
      "程序设计基础",
      "软件工程基础",
      "数据库设计基础",
      "计算机系统",
    ],
  };

  return (
    <Container maxWidth="lg" sx={{ height: "calc(100vh - 64px)", mt: 2 }}>
      <Grid container spacing={isMobile ? 2 : 3} sx={{ height: "100%" }}>
        {/* 左侧选择题显示区域 */}
        <Grid item xs={12} md={8} sx={{ height: "100%" }}>
          <Paper
            elevation={3}
            sx={{
              p: isMobile ? 1 : 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* 显示Alert并添加“我已知晓”按钮 */}
            {showAlert && (
              <Alert
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setShowAlert(false)}
                  >
                    我已知晓
                  </Button>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>提示</AlertTitle>
                您可以使用 <strong>左右箭头键</strong> 切换题目，使用{" "}
                <strong>1、2、3、4</strong> 数字键选择选项 A、B、C、D。
              </Alert>
            )}
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: isMobile ? "1.2rem" : "1.5rem" }}
            >
              选择题
            </Typography>
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              <MultipleChoiceQuestion
                questions={questions}
                currentQuestionIndex={currentQuestionIndex}
                userAnswers={userAnswers}
                handleAnswer={handleAnswer}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                isMobile={isMobile}
                isMember={isMember} // 传递 isMember
                setShowPurchaseDialog={setShowPurchaseDialog} // 传递 setShowPurchaseDialog
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? 2 : 0,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
                fullWidth={isMobile}
              >
                上一题
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
                fullWidth={isMobile}
              >
                下一题
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* 右侧题目目录区域 */}
        {!isMobile && (
          <Grid item xs={12} md={4} sx={{ height: "100%" }}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MultipleChoiceSwitcher
                questions={questions}
                selectedQuestionIndex={currentQuestionIndex}
                setSelectedQuestionIndex={setCurrentQuestionIndex}
                userAnswers={userAnswers}
                tags={tags}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isMember={isMember} // 传递 isMember
                setShowPurchaseDialog={setShowPurchaseDialog} // 传递 setShowPurchaseDialog
              />
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* 购买提示弹窗 */}
      <Dialog
        open={showPurchaseDialog}
        onClose={() => setShowPurchaseDialog(false)}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent>
          您只能访问前 100 道题目。成为会员可以解锁更多内容！
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPurchaseDialog(false)}>取消</Button>
          <Button
            onClick={() => (window.location.href = "/upgrade")}
            color="primary"
            variant="contained"
          >
            立即购买
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MultipleChoicePage;
