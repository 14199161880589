// src/components/ProgrammingPage/AnswerModal.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const AnswerModal = ({
  isOpen,
  onClose,
  selectedQuestion,
  handleUseAnswerCode,
  modalStyle,
}) => (
  <Modal open={isOpen} onClose={onClose}>
    <Box sx={modalStyle}>
      <Typography variant="h6" gutterBottom>
        正确答案
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '400px',
          overflow: 'auto',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: '#f5f5f5',
        }}
      >
        <pre>
          <code>
            {selectedQuestion ? selectedQuestion.answer_code : '暂无答案'}
          </code>
        </pre>
      </Box>
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUseAnswerCode}
        >
          使用该代码
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default AnswerModal;
