// src/theme.js
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3776AB', // Python 蓝色
    },
    secondary: {
      main: '#FFD43B', // Python 黄色
    },
    background: {
      default: '#20232a',
      paper: '#282c34',
    },
    text: {
      primary: '#dcdcdc',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, sans-serif',
  },
  body2: {
    fontSize: '16px', // 统一设置 body2 的字体大小为 16px
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // 统一按钮的圆角
          textTransform: 'none', // 取消按钮文字的全大写
        },
        containedPrimary: {
          backgroundColor: '#3776AB', // Python 蓝色
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#28527a',
          },
        },
        containedSecondary: {
          backgroundColor: '#FFD43B', // Python 黄色
          color: '#000000',
          '&:hover': {
            backgroundColor: '#ccab2b',
          },
        },
        outlinedPrimary: {
          borderColor: 'rgba(255, 255, 255, 0.3)',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          },
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3776AB', // Python 蓝色
    },
    secondary: {
      main: '#FFD43B', // Python 黄色
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
    text: {
      primary: '#000000',
      secondary: '#333333',
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // 统一按钮的圆角
          textTransform: 'none', // 取消按钮文字的全大写
        },
        containedPrimary: {
          backgroundColor: '#3776AB', // Python 蓝色
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#28527a',
          },
        },
        containedSecondary: {
          backgroundColor: '#FFD43B', // Python 黄色
          color: '#000000',
          '&:hover': {
            backgroundColor: '#ccab2b',
          },
        },
        outlinedPrimary: {
          borderColor: '#3776AB',
          backgroundColor: 'rgba(55, 118, 171, 0.1)',
          color: '#3776AB',
          '&:hover': {
            backgroundColor: 'rgba(55, 118, 171, 0.2)',
          },
        },
      },
    },
  },
});

export { lightTheme, darkTheme };