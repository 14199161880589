import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/mode-python';

const Editor = ({ code, setCode, theme, indentSize, codeCompletion, fontSize }) => {
  return (
    <AceEditor
      mode="python"
      theme={theme}
      name="code-editor"
      fontSize={fontSize}  // 传递字体大小
      value={code}
      onChange={setCode}
      width="100%"
      height="100%"
      setOptions={{
        useWorker: false,
        tabSize: indentSize,
        enableBasicAutocompletion: codeCompletion === '单词级补全',  // 确保是布尔值
        enableLiveAutocompletion: codeCompletion === '单词级补全',  // 确保是布尔值
        enableSnippets: true,
        showLineNumbers: true,
        showGutter: true,
        vScrollBarAlwaysVisible: true,
      }}
    />
  );
};

export default Editor;