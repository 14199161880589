import React from "react";
import { Box, Button } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const SubmissionSection = ({ handleSubmit, handleShowAnswer, isLoading }) => {
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "row" },
        gap: 2,
      }}
    >
      <Button
        variant="contained"
        color="warning"
        onClick={handleShowAnswer}
        startIcon={<VisibilityIcon />}
      >
        显示答案
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit} // Directly use handleSubmit
        startIcon={<PlayArrowIcon />}
        disabled={isLoading} // Button is disabled when loading
      >
        提交
      </Button>
    </Box>
  );
};

export default SubmissionSection;
