import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';

// 动画效果
const scaleAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

// 定义按钮的样式
const AnimatedBox = styled(Box)(({ isselected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',  // 根据您之前的图片，保持适当的内边距
  marginBottom: '8px',  // 按钮之间的间距
  border: isselected ? '2px solid #1976d2' : '1px solid #e0e0e0',
  borderRadius: '8px',  // 圆角调整
  cursor: 'pointer',
  transition: 'background-color 0.3s, transform 0.3s, border-color 0.3s',
  backgroundColor: isselected ? '#e0f7fa' : 'inherit',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  ...(isselected && {
    animation: `${scaleAnimation} 0.5s ease-in-out`,
  }),
}));

// 定义类型标识圆圈
const TypeCircle = ({ type }) => {
  return (
    <Box
      sx={{
        width: 24,  // 宽度为24px
        height: 24,  // 高度为24px
        borderRadius: '50%',
        backgroundColor: '#1976d2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '14px',  // 字体大小14px
        fontWeight: '400',
        marginRight: '8px',  // 图标和文字之间的间距
      }}
    >
      {type}
    </Box>
  );
};

// 定义问题项
const QuestionItem = ({ question, isSelected, onSelect }) => {
  return (
    <AnimatedBox
      onClick={() => onSelect(question)}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onSelect(question);
        }
      }}
      isselected={isSelected ? 1 : 0}  // 使用 isselected 而不是 isSelected，防止传递给 DOM
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TypeCircle type={question.type} />
        <Typography sx={{ ml: 1, fontSize: '16px' }}>{question.name}</Typography>  {/* 字体大小为16px */}
      </Box>
    </AnimatedBox>
  );
};

export default QuestionItem;
