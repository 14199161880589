import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginDialog = ({ open, onClose }) => {
  const [loginUrl, setLoginUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const closeButtonRef = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // 防止每次重新生成 handleLogin
  const handleLogin = useCallback(async () => {
    if (!isMounted.current) return; // 确保组件未卸载
    setIsLoading(true);
    setError(null);
    try {
      const urlParams = new URL(loginUrl);
      const uid = urlParams.searchParams.get("uid");

      if (!uid) {
        if (isMounted.current) {
          setError("登录地址无效，未找到 uid 参数。");
          setIsLoading(false);
        }
        return;
      }

      const response = await axios.post("/auth/login", { uid });
      if (response.status === 200 && response.data.token) {
        const { token } = response.data;
        localStorage.setItem("token", token);
        if (isMounted.current) {
          onClose(); // 关闭对话框
          alert("登录成功，已保持登录状态！");
        }
      } else {
        if (isMounted.current) {
          setError("登录失败，请检查您的登录地址。");
        }
      }
    } catch (error) {
      if (isMounted.current) {
        console.error("登录失败:", error);
        setError("登录失败，请重试。");
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }, [loginUrl, onClose]); // 依赖 loginUrl 和 onClose

  const handleClose = () => {
    onClose();
    closeButtonRef.current?.focus(); // 将焦点返回到关闭按钮
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted fullWidth maxWidth="xs">
      <DialogTitle sx={{ mb: 4 }}>登录</DialogTitle>
      <DialogContent>
        <TextField
          label="私有登录地址"
          placeholder="请输入包含 uid 参数的登录链接"
          fullWidth
          variant="outlined"
          value={loginUrl}
          onChange={(e) => setLoginUrl(e.target.value)}
          sx={{
            mb: 3,
            "& .MuiInputLabel-root": {
              top: "-5px",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(0, 1.5px) scale(0.75)",
            },
          }}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button ref={closeButtonRef} onClick={handleClose}>
          取消
        </Button>
        <Button
          onClick={handleLogin}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "登录"}
        </Button>
      </DialogActions>
      <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate("/help")}
        >
          忘记登录地址？重新发送登录链接
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
