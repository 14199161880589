import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // 用于路由跳转
// 引入独立的子组件
import QuestionHeader from "../components/ProgrammingPage/QuestionHeader";
import EditorSection from "../components/ProgrammingPage/EditorSection";
import SubmissionSection from "../components/ProgrammingPage/SubmissionSection";
import OutputDisplay from "../components/ProgrammingPage/OutputDisplay";
import AnswerModal from "../components/ProgrammingPage/AnswerModal";
import ConfirmDialog from "../components/ProgrammingPage/ConfirmDialog";
import EditorSettingsModal from "../components/ProgrammingPage/EditorSettingsModal";
import QuestionSwitcher from "../components/ProgrammingPage/QuestionSwitcher";

const ProgrammingPage = ({ theme }) => {
  const [AllQuestions, setAllQuestions] = useState([]);
  const [userCode, setUserCode] = useState(""); // 当前编辑器内容
  const [output, setOutput] = useState(null); // 初始为 null，表示没有输出
  const [selectedQuestion, setSelectedQuestion] = useState(null); // 选中的题目
  const [isModalOpen, setIsModalOpen] = useState(false); // 控制编辑器设置窗口
  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false); // 控制答案弹窗
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // 控制确认替换的弹窗
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false); // 控制升级提示弹窗
  const [isLoading, setIsLoading] = useState(false); // 控制加载状态
  const [taskStatus, setTaskStatus] = useState(""); // 用于显示任务状态
  const [upgradeDialogMessage, setUpgradeDialogMessage] = useState(""); // 升级提示弹窗的消息

  const MAX_LINES = 200; // 代码最大行数限制
  const MAX_CHARS = 10000; // 代码最大字符数限制

  const navigate = useNavigate(); // 用于路由跳转

  const [editorSettings, setEditorSettings] = useState({
    theme: theme === "dark" ? "monokai" : "github",
    indentSize: 4,
    codeCompletion: "单词级补全",
    fontSize: 22,
  });

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"));

  // 获取题目数据并默认选中第一题
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get("/api/questions");
        setAllQuestions(response.data);

        // 如果有题目，默认选择第一道题目
        if (response.data.length > 0) {
          setSelectedQuestion(response.data[0]);
          setUserCode(response.data[0].source_code);
        }
      } catch (error) {
        console.error("获取题目信息时发生错误:", error);
      }
    };


    fetchQuestions();
  }, []);
  useEffect(() => {
    // 当 selectedQuestion 变化时，重置 output
    setOutput(null);
  }, [selectedQuestion]);
  const handleSubmit = useCallback(async () => {
    if (isLoading) return;  // 如果已经在加载中，直接返回，不重复提交
    setIsLoading(true);
    setTaskStatus("");

    const codeLines = userCode.split("\n");
    const codeLength = userCode.length;

    // 检查代码行数和字符数
    if (codeLines.length > MAX_LINES) {
      setOutput({
        message: `代码超过了最大行数限制 (${MAX_LINES} 行)`,
        isError: true,
      });
      setIsLoading(false);
      return;
    }
    if (codeLength > MAX_CHARS) {
      setOutput({
        message: `代码超过了最大字符数限制 (${MAX_CHARS} 字符)`,
        isError: true,
      });
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      // 用户未登录，显示升级提示弹窗
      setUpgradeDialogMessage("您尚未登录或会员资格已过期，是否前往升级会员资格？");
      setIsUpgradeDialogOpen(true);
      setIsLoading(false);
      return;
    }

    const requestData = {
      question_id: selectedQuestion ? selectedQuestion.id : null,
      code: userCode,
    };

    // 定义轮询函数
    const pollJobStatus = (jobId) => {
      const interval = setInterval(async () => {
        try {
          // 发送请求查询任务状态
          const statusResponse = await axios.get(`/api/job_status/${jobId}`);
          const { status } = statusResponse.data;

          // 根据任务状态更新前端
          setTaskStatus(status); // 更新taskStatus状态
          if (status === "finished") {
            clearInterval(interval); // 停止轮询
            getJobResult(jobId); // 获取评测结果
          } else if (status === "failed") {
            clearInterval(interval); // 停止轮询
            setOutput({
              message: "评测任务失败！",
              isError: true,
            });
            setIsLoading(false); // 确保加载状态被更新
          }
        } catch (error) {
          console.error("查询任务状态失败:", error);
          clearInterval(interval); // 停止轮询
          setOutput({
            message: "查询任务状态时出错，请稍后重试。",
            isError: true,
          });
          setIsLoading(false); // 确保加载状态被更新
        }
      }, 2000);
    };

    try {
      setTaskStatus("queued");
      const response = await axios.post("/api/submit_code", requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const { job_id } = response.data;

      if (job_id) {
        console.log("任务已提交，Job ID:", job_id);
        setTaskStatus("started");
        pollJobStatus(job_id);
      } else {
        setOutput({
          message: "提交失败，无法获取任务ID。",
          isError: true,
        });
        setIsLoading(false);
        setTaskStatus("");
      }
    } catch (err) {
      setIsLoading(false);
      setTaskStatus("");

      if (err.response) {
        if (err.response.status === 403) {
          const errorMessage = err.response.data.error;
          if (errorMessage === "会员资格已过期") {
            setUpgradeDialogMessage("您的会员资格已过期，是否前往升级页面？");
          } else if (
            errorMessage === "缺少令牌" ||
            errorMessage === "令牌已过期" ||
            errorMessage === "无效的令牌"
          ) {
            setUpgradeDialogMessage("您尚未登录或会员资格已过期，是否前往升级会员资格？");
          } else {
            setUpgradeDialogMessage(`提交失败：${errorMessage}`);
          }
          setIsUpgradeDialogOpen(true);
        } else {
          setOutput({
            message: `提交失败：${err.response.data.error}`,
            isError: true,
          });
        }
      } else {
        setOutput({
          message: "提交失败，服务器无响应，请稍后重试。",
          isError: true,
        });
      }
    }
  }, [userCode, selectedQuestion, MAX_LINES, MAX_CHARS, isLoading]);

  const handleUpgrade = () => {
    setIsUpgradeDialogOpen(false);
    navigate("/upgrade");
  };

  // 关闭升级弹窗
  const handleCloseUpgradeDialog = () => {
    setIsUpgradeDialogOpen(false);
  };

  // 获取评测结果
  const getJobResult = async (jobId) => {
    try {
      const resultResponse = await axios.get(`/api/get_job_result/${jobId}`);
      const resultData = resultResponse.data.result;

      // 提取 message, score, total, errors
      const { message, score, total, errors } = resultData;
      console.log(
        `Message: ${message}, Score: ${score}, Total: ${total}, Errors: ${errors}`
      );

      // 设置输出内容，确保得分和消息格式符合预期
      setOutput({
        message: `${message} 得分: ${score}/${total}`, // 构建格式化的消息
        score,
        total,
        errors,
        isError: errors.length > 0,
      });
      setTaskStatus("finished"); // 任务完成，更新状态
    } catch (error) {
      console.error("获取评测结果失败:", error);
      setOutput({
        message: "获取评测结果时出错，请稍后重试。",
        isError: true,
      });
    } finally {
      setIsLoading(false); // 确保加载状态被更新
    }
  };

  // 保存编辑器设置
  const handleSettingsSave = useCallback(
    (settings) => {
      setEditorSettings(settings);
    },
    [setEditorSettings]
  );

  // "使用答案代码" 处理
  const handleUseAnswerCode = () => {
    if (userCode.trim()) {
      setIsConfirmOpen(true); // 如果编辑器有内容，弹出确认窗口
    } else {
      setUserCode(selectedQuestion ? selectedQuestion.answer_code : "");
      setIsAnswerModalOpen(false); // 关闭答案窗口
    }
  };

  // 确认替换代码
  const handleConfirm = () => {
    setUserCode(selectedQuestion ? selectedQuestion.answer_code : "");
    setIsConfirmOpen(false); // 关闭确认弹窗
    setIsAnswerModalOpen(false); // 关闭答案窗口
  };

  // 取消确认替换
  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  };

  // 重置代码
  const resetCode = () => {
    setUserCode(selectedQuestion ? selectedQuestion.source_code : "");
  };

  // 弹窗样式
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "80%",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ p: 2, minHeight: "80vh" }}>
            <Box sx={{ mb: 2 }}>
              <QuestionHeader selectedQuestion={selectedQuestion} />
            </Box>

            <EditorSection
              isMobile={isMobile}
              userCode={userCode}
              setUserCode={setUserCode}
              editorSettings={editorSettings}
              setIsModalOpen={setIsModalOpen}
              resetCode={resetCode}
            />

            <SubmissionSection
              handleSubmit={handleSubmit}
              handleShowAnswer={() => setIsAnswerModalOpen(true)}
              isLoading={isLoading}  // 传递 isLoading 状态
            />

            <Box sx={{ mt: 2 }}>
              <OutputDisplay
                output={output}
                isLoading={isLoading}
                taskStatus={taskStatus}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <QuestionSwitcher
            AllQuestions={AllQuestions}
            setSelectedQuestion={setSelectedQuestion}
            setUserCode={setUserCode}
            setOutput={setOutput}
          />
        </Grid>
      </Grid>
      {/* 升级提示弹窗 */}
      <Dialog
        open={isUpgradeDialogOpen}
        onClose={handleCloseUpgradeDialog}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent>{upgradeDialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpgradeDialog}>取消</Button>
          <Button
            onClick={handleUpgrade}
            color="primary"
            variant="contained"
          >
            升级
          </Button>
        </DialogActions>
      </Dialog>

      {/* 编辑器设置弹窗 */}
      <EditorSettingsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSettingsSave}
        initialSettings={editorSettings}
        theme={theme}
      />

      {/* 答案展示弹窗 */}
      <AnswerModal
        isOpen={isAnswerModalOpen}
        onClose={() => setIsAnswerModalOpen(false)}
        selectedQuestion={selectedQuestion}
        handleUseAnswerCode={handleUseAnswerCode}
        modalStyle={modalStyle}
      />

      {/* 确认替换代码的弹窗 */}
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default ProgrammingPage;
