// src/pages/PaymentSuccessPage.js

import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // 解析查询参数
  const queryParams = new URLSearchParams(location.search);
  const orderNumber = queryParams.get("out_trade_no");
  const tradeNo = queryParams.get("trade_no");

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8, textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        支付成功！
      </Typography>
      <Typography variant="body1" gutterBottom>
        订单号：{orderNumber}
      </Typography>
      <Typography variant="body1" gutterBottom>
        支付宝交易号：{tradeNo}
      </Typography>
      <Typography variant="body1" gutterBottom>
        您的私有地址已发往邮箱,请在邮箱中查看，请勿泄露订单号与交易号，这将作为未来修改绑定邮箱的凭证，请保留
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHome} sx={{ mt: 4 }}>
        返回首页
      </Button>
    </Container>
  );
};

export default PaymentSuccessPage;
