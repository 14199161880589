import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CodeIcon from "@mui/icons-material/Code";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import UpdateIcon from "@mui/icons-material/Update";
import StarIcon from "@mui/icons-material/Star"; // 新的图标
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginDialog from "../components/UpgradePage/LoginDialog";

const UpgradePage = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const isMounted = useRef(false);
  const confirmButtonRef = useRef(null);
  const loginButtonRef = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      setIsConfirmDialogOpen(true);
    } else {
      alert("请输入有效的邮箱地址");
    }
  };

  const handleConfirm = async () => {
    if (!isMounted.current) return;
    setIsConfirmDialogOpen(false);
    setIsLoading(true);
    try {
      const response = await axios.post("/api/create-alipay-order", {
        email,
      });
      if (
        response.status === 200 &&
        response.data.status === "success"
      ) {
        const { pay_url } = response.data;
        window.location.href = pay_url;
      } else {
        if (isMounted.current) {
          alert("创建订单失败，请重试！");
        }
      }
    } catch (error) {
      if (isMounted.current) {
        console.error("Error creating order:", error);
        alert("创建订单失败，请重试！");
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 4, mb: 4, textAlign: isMobile ? "center" : "left" }}
    >
      <Grid
        container
        spacing={4}
        sx={
          isMobile
            ? { justifyContent: "center", alignItems: "center" }
            : {}
        }
      >
        {/* 左侧：会员专属服务展示 */}
        {!isMobile && (
          <Grid item xs={12} md={7}>
            <Paper elevation={3} sx={{ p: 4, minHeight: "70vh" }}>
              <Typography
                variant="h5" // 调整为 h5
                gutterBottom
                sx={{
                  color: isDarkMode
                    ? theme.palette.secondary.main
                    : "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StarIcon
                  sx={{
                    fontSize: "1.8rem",
                    mr: 1,
                    color: "#FF5722",
                  }}
                />
                会员专属服务
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <LibraryBooksIcon
                    sx={{ mr: 1, color: "#1976d2" }}
                  />
                  解锁完整选择题题库
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <CodeIcon sx={{ mr: 1, color: "#1976d2" }} />
                  解锁编程题库
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <PlayCircleFilledIcon
                    sx={{ mr: 1, color: "#1976d2" }}
                  />
                  在线代码评测功能
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <UpdateIcon sx={{ mr: 1, color: "#1976d2" }} />
                  定期更新和维护
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {/* 右侧：购买区域 */}
        <Grid item xs={12} md={isMobile ? 12 : 5}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              minHeight: "70vh",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start", // 从顶部开始排列
              alignItems: isMobile ? "center" : "stretch",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: isDarkMode
                  ? theme.palette.secondary.main
                  : "inherit",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2, // 增加下边距
              }}
            >
              <EmojiEventsIcon
                sx={{
                  fontSize: "1.8rem",
                  mr: 1,
                  color: "#FF5722",
                }}
              />
              升级会员
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // 固定为 row
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <LocalOfferIcon
                sx={{
                  fontSize: "2rem",
                  color: "#FF5722",
                  mr: 1, // 固定右边距
                  // mb: isMobile ? 1 : 0, // 移除移动端的下边距
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#FF5722",
                  mr: 2, // 固定右边距
                }}
              >
                28.8元
              </Typography>
              <Typography
                variant="h8"
                sx={{
                  textDecoration: "line-through",
                  color: "gray",
                }}
              >
                36元/半年（180天）
              </Typography>
            </Box>
            <Box component="form" sx={{ mt: 4, width: "100%" }}>
              <TextField
                label="常用邮箱地址"
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                sx={{ mb: 3 }}
              />
              <Button
                ref={confirmButtonRef}
                variant="contained"
                color={
                  !isDarkMode ? "primary" : "secondary"
                }
                fullWidth
                onClick={handleSubmit}
                sx={{ padding: "12px", fontSize: "16px" }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  />
                ) : (
                  "立即购买"
                )}
              </Button>
              {/* 确认弹窗 */}
              <Dialog
                open={isConfirmDialogOpen}
                onClose={() => {
                  setIsConfirmDialogOpen(false);
                  confirmButtonRef.current?.focus();
                }}
                keepMounted
              >
                <DialogTitle>确认邮箱地址</DialogTitle>
                <DialogContent>
                  <Typography>
                    您确定您的邮箱是：{email} 吗？
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setIsConfirmDialogOpen(false);
                      confirmButtonRef.current?.focus();
                    }}
                    color="primary"
                  >
                    返回修改
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                  >
                    确认
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            {/* 登录按钮 */}
            <Button
              ref={loginButtonRef}
              variant="text"
              color="primary"
              sx={{ mt: 3, fontSize: "1rem" }}
              onClick={() =>
                setIsLoginDialogOpen(true)
              }
            >
              已购买？立即登录
            </Button>
            <LoginDialog
              open={isLoginDialogOpen}
              onClose={() => {
                setIsLoginDialogOpen(false);
                loginButtonRef.current?.focus();
              }}
            />
            {/* 在移动设备上显示会员专属服务信息 */}
            {isMobile && (
              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: isDarkMode
                      ? theme.palette.secondary.main
                      : "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StarIcon
                    sx={{
                      fontSize: "1.8rem",
                      mr: 1,
                      color: "#FF5722",
                    }}
                  />
                  会员专属服务
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <LibraryBooksIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    解锁完整选择题题库
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <CodeIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    解锁编程题库
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <PlayCircleFilledIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    在线代码评测功能
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <UpdateIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    定期更新和维护
                  </Typography>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UpgradePage;
