import React, { useEffect } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const MultipleChoiceQuestion = ({
  questions,
  currentQuestionIndex,
  userAnswers,
  handleAnswer,
  handleNext,
  handlePrevious,
  isMobile,
  isMember,
  setShowPurchaseDialog, // 接收 setShowPurchaseDialog
}) => {
  const questionsPerPage = 1; // 每次显示1题
  const startIndex = currentQuestionIndex;
  const endIndex = Math.min(startIndex + questionsPerPage, questions.length);
  const currentQuestions = questions.slice(startIndex, endIndex);

  // 监听键盘事件
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowRight": // 下一题
          handleNext();
          break;
        case "ArrowLeft": // 上一题
          handlePrevious();
          break;
        case "1": // 选择 A
        case "2": // 选择 B
        case "3": // 选择 C
        case "4": // 选择 D
          if (!isMember && currentQuestionIndex >= 100) {
            // 非会员用户尝试答题，弹出购买提示
            setShowPurchaseDialog(true);
            return;
          }
          handleAnswer(currentQuestionIndex, parseInt(event.key) - 1);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // 清理事件监听器
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    currentQuestionIndex,
    handleNext,
    handlePrevious,
    handleAnswer,
    isMember,
    setShowPurchaseDialog,
  ]);

  // 非会员用户访问超过第 100 题，显示购买提示
  useEffect(() => {
    if (!isMember && currentQuestionIndex >= 100) {
      setShowPurchaseDialog(true);
    }
  }, [isMember, currentQuestionIndex, setShowPurchaseDialog]);

  // 如果非会员用户访问超过第 100 题，显示提示信息
  if (!isMember && currentQuestionIndex >= 100) {
    return (
      <Typography variant="h6">
        您只能访问前 100 道题目。成为会员可以解锁更多内容！
      </Typography>
    );
  }

  return (
    <Box>
      {currentQuestions.map((question, index) => {
        const questionIndex = startIndex + index;
        const userAnswer = userAnswers[questionIndex];
        const isCorrect = userAnswer === question.answer;

        return (
          <Box key={questionIndex} sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              {`${questionIndex + 1}. ${question.question}`}
            </Typography>
            <RadioGroup
              value={userAnswer !== null ? userAnswer : ""}
              onChange={(e) =>
                handleAnswer(questionIndex, parseInt(e.target.value))
              }
            >
              {question.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  value={optionIndex}
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {option}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
            {userAnswer !== null && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                {isCorrect ? (
                  <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
                ) : (
                  <CancelIcon sx={{ color: "red", mr: 1 }} />
                )}
                <Typography variant="body1">
                  {isCorrect ? "正确" : "错误"} - {question.explanation}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default MultipleChoiceQuestion;
