import React from 'react';
import { Typography, Box } from '@mui/material';

const QuestionHeader = ({ selectedQuestion }) => {
  if (!selectedQuestion) {
    return <Typography variant="h6">请选择一个题目</Typography>;
  }

  return (
    <Box>
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontSize: {
            xs: '1.6rem',
            sm: '1.6rem',
            md: '1.6rem',
          },
          whiteSpace: 'pre-line',  // 让换行符有效
        }}
      >
        {selectedQuestion.name}
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          fontSize: {
            xs: '1.4rem',
            sm: '1.4rem',
            md: '1.2rem',
          },
          whiteSpace: 'pre-line',  // 让换行符有效
        }}
      >
        {selectedQuestion.title}
      </Typography>
    </Box>
  );
};

export default QuestionHeader;
