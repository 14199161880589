// MultipleChoiceSwitcher.js

import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import TagFilter from "./TagFilter";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import "./MultipleChoiceSwitcher.css";

const MultipleChoiceSwitcher = ({
  questions,
  userAnswers,
  setSelectedQuestionIndex,
  tags,
  selectedTags,
  setSelectedTags,
  currentPage,
  setCurrentPage,
  isMember,
  setShowPurchaseDialog,
}) => {
  const questionsPerPage = 25;

  const theme = useTheme(); // 获取当前主题
  const isDarkMode = theme.palette.mode === 'dark'; // 判断是否是暗模式

  // 基于 selectedTags 过滤 questions
  const filteredQuestions = questions.filter(
    (question) =>
      selectedTags.length === 0 ||
      selectedTags.some((tag) => question.tags.includes(tag))
  );

  const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = currentPage * questionsPerPage;
  const endIndex = Math.min(startIndex + questionsPerPage, filteredQuestions.length);
  const currentQuestions = filteredQuestions.slice(startIndex, endIndex);

  const handleQuestionClick = (globalIndex) => {
    if (!isMember && globalIndex >= 100) {
      setShowPurchaseDialog(true);
      return;
    }
    setSelectedQuestionIndex(globalIndex);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* 标题和分页按钮 */}
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
            variant="contained"
            color="primary"
          >
            上一组
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
            variant="contained"
            color="secondary"
          >
            下一组
          </Button>
        </Box>
      </Box>

      {/* 题目列表 */}
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <Box className="question-container" sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: 2 }}>
          {currentQuestions.map((question) => {
            const globalIndex = questions.indexOf(question); // 获取原始全局索引
            const answerStatus =
              userAnswers[globalIndex] === null
                ? "unfinished"
                : userAnswers[globalIndex] === question.answer
                  ? "correct"
                  : "incorrect";
            const isLocked = !isMember && globalIndex >= 100;

            // 动态设置背景色和字体颜色
            const backgroundColor =
              answerStatus === "unfinished"
                ? isDarkMode
                  ? "#424242"
                  : "#f5f5f5"
                : answerStatus === "correct"
                  ? isDarkMode
                    ? "#388e3c"
                    : "#c8e6c9"
                  : isDarkMode
                    ? "#d32f2f"
                    : "#ffcdd2";

            const textColor =
              answerStatus === "unfinished"
                ? isDarkMode
                  ? "#ffffff"
                  : "#000000"
                : "#ffffff"; // 正确或错误都使用白色字体

            return (
              <Box
                key={globalIndex}
                className={`question-box ${answerStatus} ${isLocked ? "locked" : ""}`}
                onClick={() => handleQuestionClick(globalIndex)}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                  width: "50px",
                  borderRadius: "8px",
                  backgroundColor: backgroundColor,
                  color: textColor,
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: isLocked ? "#ffccbc" : "#e3f2fd",
                  },
                }}
              >
                {/* 题号 */}
                <Typography variant="body1" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                  {globalIndex + 1}
                </Typography>

                {/* 锁图标 */}
                {isLocked && (
                  <LockOutlinedIcon
                    sx={{
                      position: "absolute",
                      top: "3px",
                      right: "3px",
                      fontSize: "0.875rem",
                      color: "#FF5722",
                      opacity: 0.7,
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* 标签过滤器 */}
      <Box sx={{ mt: 2 }}>
        <TagFilter
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Box>
    </Box>
  );
};

export default MultipleChoiceSwitcher;
