// src/components/ProgrammingPage/EditorSection.js
import React from 'react';
import { Box, Button } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Editor from './Editor';

const EditorSection = ({
  isMobile,
  userCode,
  setUserCode,
  editorSettings,
  setIsModalOpen,
  resetCode,
}) => (
  <Box>
    <Box
      sx={{
        mb: 2,
        mt: 2,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
      }}
    >
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        编辑器设置
      </Button>
      <Button
        variant="outlined"
        onClick={resetCode}
        startIcon={<RestartAltIcon />}
      >
        重置
      </Button>
    </Box>

    <Box
      sx={{
        width: '100%',
        height: isMobile ? '300px' : '500px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Editor
        code={userCode}
        setCode={setUserCode}
        theme={editorSettings.theme}
        indentSize={editorSettings.indentSize}
        codeCompletion={editorSettings.codeCompletion}
        fontSize={editorSettings.fontSize}
      />
    </Box>
  </Box>
);

export default EditorSection;
