import React from 'react';
import { Box, Alert, AlertTitle, Typography, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const OutputDisplay = ({ output, isLoading, taskStatus }) => {
  // 如果任务正在加载或任务正在处理中，显示加载图标或进度提示
  if (isLoading || taskStatus === 'queued' || taskStatus === 'started') {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <CircularProgress />
        <Typography variant="body2" sx={{ ml: 2 }}>
          {taskStatus === 'queued'
            ? '代码已提交，正在排队...'
            : '代码正在处理中，请稍候...'}
        </Typography>
      </Box>
    );
  }

  // 如果没有输出，且任务已经完成或失败，则不渲染任何内容
  if (!output) return null;

  const renderIcon = () => {
    if (output.isError || output.score === 0) {
      return <ErrorIcon style={{ color: 'red' }} />;
    } else if (output.score === output.total) {
      return <CheckCircleIcon style={{ color: 'green' }} />;
    } else {
      return <WarningAmberIcon style={{ color: 'orange' }} />;
    }
  };

  return (
    <Alert
      severity={
        output.isError
          ? 'error'
          : output.score === output.total
          ? 'success'
          : 'warning'
      }
      icon={renderIcon()}
    >
      <AlertTitle>{output.isError ? '错误' : '评测结果'}</AlertTitle>
      {output.message}
      {output.errors && output.errors.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="error">
            错误详情:
          </Typography>
          <ul>
            {output.errors.map((error, index) => (
              <li key={index}>
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Alert>
  );
};

export default OutputDisplay;
